import Vue from 'vue';
import { BASE_URL } from './base.service';

const STATS_BASE_URL = BASE_URL;

export const STATS_SERVICE = {
	stats: function (params: any) {
		return Vue.http.get(
			`${STATS_BASE_URL}/${params.resource}/${params.query}`, {
				params: {
					season: params.season,
					league: params.league,
					gameType: params.gameType,
					orderBy: params.orderBy,
					count: params.limitRows,
					ha: params.ha,
					team: params.team,
					playerId: params.playerId
				}
			});
	}
};
