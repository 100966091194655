




































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Context} from '@/model/site/context.model';
import {Box} from '@/model/site/components.model';
import PanelBox from '@/components/ui/PanelBox.vue';
import {FOCUS_LEAGUE_ID, FOCUS_SEASON_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {LeagueId} from '@/model/core/core-data.model';
import SEASON_SERVICE from '@/services/season.service';
// @ts-ignore
// noinspection TypeScriptCheckImport
import StatsBaseLayout from './StatsBaseLayout.vue';

// Vue.use(Table);


@Component({
  components: {
    StatsBaseLayout,
    PanelBox,
  },
})
export default class StatsPortalLayout extends Vue {

  @Prop(Array) selectionComponents?: any[];
  @Prop(Array) boxes?: Box[];
  @Prop(Object) context?: Context;
  @Prop(String) boxTitle?: string;

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId?: string;

  static readonly FIXED_LEAGUE_IDS: LeagueId[] = ['a', 'b'];

  get selectionProperties() {
    return {
      fixLeagueIds: StatsPortalLayout.FIXED_LEAGUE_IDS,
      useFocus: true,
    };
  }

  get safeLeagueId() {
    if (!this.focusLeagueId || StatsPortalLayout.FIXED_LEAGUE_IDS.filter(l => l !== this.focusLeagueId).length === 0) {
      return StatsPortalLayout.FIXED_LEAGUE_IDS[0];
    }
    return this.focusLeagueId;
  }

  boxProperties(box: Box) {
    return {...this.context, ...box.props};
  }

  get seasonDesc() {
    if (!this.focusSeasonId) {
      return '';
    }
    return SEASON_SERVICE.seasonDesc(this.focusSeasonId, true, 'Saison ');
  }

}
