













import {Component, Vue} from 'vue-property-decorator';
import {LeagueId} from '@/model/core/core-data.model';
import {FOCUS_LEAGUE_ID, SET_FOCUS_LEAGUE_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';

@Component({
  components: {},
})
export default class StatsLeagueSelection extends Vue {

  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: LeagueId;

  leagueIds: LeagueId[] = ['a', 'b'];

  select(leagueId: LeagueId) {
    this.updateLeagueId(leagueId);
  }

  updateLeagueId(leagueId: LeagueId) {
    this.$store.commit(SET_FOCUS_LEAGUE_ID, leagueId);
  }

}
