







import {Component, Prop, Vue} from 'vue-property-decorator';
import { COLUMN_FORMATTERS } from '@/services/columns/columns.base';
import { SET_FOCUS_SEASON_ID } from '@/store/context.store';

@Component({})
export default class GridSeasonLink extends Vue {

  @Prop() linkTarget?: string;
  // from bulma-table
  @Prop() props?: any;

  get value(): string {
    if (this.props) {
      return this.props.value;
    }
    Vue.$log.warn('No value for SeasonLink');
    return '';
  }

  get seasonDesc() {
    if (!this.value) {
      return '';
    }
    return COLUMN_FORMATTERS.formatSeason(this.props);
  }

  get seasonId() {
    return this.value;
  }

  seasonIdChange() {
    if (this.seasonId) {
      this.$store.commit(SET_FOCUS_SEASON_ID, this.seasonId);
    }
  }

}

