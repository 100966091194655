export type StatsResource = 'playerstats' | 'goaliestats';
export type QueryType = 'top';

export type ChartType = 'bar';

export type HA = 'h' | 'a' | 'both';

export class StatsTopGameTO {
	constructor(readonly title: string,
				readonly sortField: string,
				private readonly _dataField?: string) {
	}

	get dataField(): string {
		return this._dataField || this.sortField;
	}
}

export interface StatsData {
	seasonId: string;
	leagueIds: string[];
	gameTypeIds: string[];
}

export interface StatsParams {
	resource: string;
	query: string;
	season?: string;
	league?: string;
	gameType?: string;
	team?: string;
	orderBy?: string;
	ha?: string;
	playerId?: string;
	limitRows?: string;
}

export class StatsTopPlayerTO {
	private readonly DEFAULT_ROWS = {
		'playerstats': 10,
		'goaliestats': 5,
	};

	constructor(readonly title: string,
				private readonly _resource: StatsResource,
				private readonly _query: QueryType,
				private readonly _dataField: string | string[],
				private readonly _sortField?: string,
				private readonly _limitRows?: number) {
	}

	get resource(): string {
		return this._resource;
	}

	get query(): string {
		return this._query;
	}

	get sortField(): string {
		return this._sortField || this.dataField;
	}

	get dataField(): string {
		return Array.isArray(this._dataField) ? this._dataField[0] : this._dataField;
	}

	get dataFields(): string[] {
		return Array.isArray(this._dataField) ? this._dataField : [this._dataField];
	}

	get fieldIds(): string[] {
		return ['curTeamId', 'playerId', ...this.dataFields];
	}

	get limitRows(): number {
		return this._limitRows || this.DEFAULT_ROWS[this._resource];
	}

	get header(): boolean {
		return Array.isArray(this._dataField);
	}

}

export class StatsTeamTO {
	constructor(readonly title: string,
				private readonly _ha: HA,
				readonly query: string,
				readonly dataFields: string[],
				private readonly _chartType?: ChartType) {
	}

	get id(): string {
		return this.dataFields.join('-');
	}

	get ha(): string {
		return this._ha;
	}

	get chartType(): string {
		return this._chartType || 'bar';
	}


}
