







import {Component, Prop, Vue} from 'vue-property-decorator';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import TEAM_SERVICE from '@/services/team.service';

@Component({
  components: {HfTeamLogo},
})
export default class GridTeamLogo extends Vue {

  // from bulma-table
  @Prop() props?: any;

  // from ag-grid
  params: any;

  title: string = '';

  get value() {
    if (this.params) {
      return this.params.value;
    }
    if (this.props) {
      return this.props.value;
    }
    Vue.$log.warn('No value for TeamLogo');
    return '';
  }

  get id() {
    return `clubLogo-${this.teamId}`;
  }

  get teamId() {
    return this.value;
  }

  get seasonId() {
    if (this.props && this.props.data && this.props.data.seasonId) {
      return this.props.data.seasonId;
    }
    return '';
  }

  get leagueId() {
    if (this.props && this.props.data && this.props.data.leagueId) {
      return this.props.data.leagueId;
    }
    return '';
  }

  created() {
    if (!this.teamId) {
      this.title = 'n/a';
    } else if (!this.seasonId || !this.leagueId) {
      this.title = this.teamId.toUpperCase();
    } else {
      TEAM_SERVICE.teamById(this.teamId, this.seasonId, this.leagueId)
        .then((resp: any) => {
          const team = resp.data;
          this.title = team.longName;
        })
        .catch((error: any) => this.title = this.teamId.toUpperCase());
    }
  }

}
