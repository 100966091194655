















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {STATS_SERVICE} from '@/services/stats.service';
import COLUMNS from '@/services/columns/column.service';
import GridCountryFlag from '@/components/stats/renderer/GridCountryFlag.vue';
import GridPlayerLink from '@/components/stats/renderer/GridPlayerLink.vue';
import GridTeamLogo from '@/components/stats/renderer/GridTeamLogo.vue';
import GridSeasonLink from '@/components/stats/renderer/GridSeasonLink.vue';
import GridGameLink from '@/components/stats/renderer/GridGameLink.vue';
import {StatsParams} from '@/model/stats/stats.model';
import StatsTableMixin from '@/mixins/stats-table.mixin';
import {mixins} from 'vue-class-component';
import {FOCUS_GAME_TYPE_ID, FOCUS_LEAGUE_ID, FOCUS_SEASON_ID, FOCUS_TEAM_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {COLUMN_TYPES} from '@/services/columns/columns.base';
import BTag from 'buefy/src/components/tag/Tag.vue';
import BTooltip from 'buefy/src/components/tooltip/Tooltip.vue';

@Component({
  components: {BTooltip, GridCountryFlag, GridPlayerLink, GridTeamLogo, GridGameLink, GridSeasonLink, BTag},
})
export default class DataTable extends mixins(StatsTableMixin) {

  // TODO use BulmaTable, rename that to DataTable and rename this to StatsDataTable

  @Prop({required: true}) resource!: string;
  @Prop({required: true}) query!: string;
  @Prop({required: true}) fieldIds!: string[];
  @Prop({required: true}) queryParams!: StatsParams;

  @Prop() title!: string;
  @Prop() pageSize?: number;

  @Prop({default: false}) suppressPaging!: boolean;
  @Prop({default: false}) suppressSorting!: boolean;
  @Prop({default: true}) showHeader!: boolean;
  @Prop({default: true}) showFooter!: boolean;
  @Prop() defaultSort?: string;
  @Prop({default: 'desc'}) defaultSortDirection?: string;

  // ag-grid-only
  @Prop({default: false}) agGrid!: boolean;
  @Prop({default: true}) autoSizeColumns!: boolean;

  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;
  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;
  @Getter(FOCUS_TEAM_ID) focusTeamId!: string | undefined;

  static readonly DEFAULT_PAGE_SIZE = 20;
  static readonly MAX_PAGE_SIZE = 50;

  readonly HEADER_HEIGHT = 30;
  readonly FOOTER_HEIGHT = 30;
  readonly ROW_HEIGHT = 24;
  // readonly PAGER_HEIGHT = 32;

  rowData = [];
  // rowDataCache: any = {};
  // gridReady = false;
  currentPage: number = 1;

  isCreated = false;
  isDeactivated = false;
  isDirty = false;
  isLoading = false;
  hasError = false;

  onPageChange(page: number) {
    this.$emit('hf-page-change', page);
  }

  reload() {
    this.loadData({});
  }

  get emptyMessage() {
    if (this.isLoading) {
      return 'Lädt ...';
    }
    return 'Keine Daten';
  }

  format(value: string, formatter: (params: object) => string): string {
    if (formatter instanceof Function) {
      const params = {value};
      return formatter(params);
    }
    return value;
  }

  cellClass(value: string, cellClass: any) {
    if (cellClass instanceof Function) {
      const params = {value};
      return cellClass(params);
    }
    return cellClass;
  }

  get tableClass() {
    if (!this.showHeader) {
      return 'hf-no-header';
    }
    return '';
  }

  /**
   * label       Column header text, also used to identify column if custom-key prop is missing  String  —  —
   * custom-key  Unique identifier, use when label is missing or there are duplicate label names  String, Number  —  this.label
   * field       Property of the object the column is attributed, used for sorting  String  —  —
   * meta         Meta prop to add anything, useful when creating custom headers  Any  —  —
   * width       Column fixed width in pixels  Number  —  —
   * numeric     Align the cell content to the right, sort icon on left  Boolean  —  false
   * centered     Align the cell content to the center  Boolean  —  false
   * sortable     Whether the column can be sorted  Boolean  —  false
   * visible     Whether the column is visible  Boolean  —  true
   * custom-sort Custom sort method, works when is sortable  Function (a: Object, b: Object, isAsc: Boolean)
   */
  get columnsBulma() {
    const columns = this.createColumnDefinitions(this.resource, this.fieldIds, true);
    columns.forEach(col => {
      // copy fields ag-grid => bulma
      col.label = col.headerName || '';
      col.numeric = (col.type === 'numericColumn');
      col.sortable = !col.unsortable;
      col['custom-sort'] = col.comparator;

      // ... resolve column-types, not overwritting
      if (COLUMN_TYPES[col.type]) {
        const type = COLUMN_TYPES[col.type];
        // Vue.$log.debug('column-type', col.type, type);
        col.label = type.headerName || col.label;
        col.numeric = (type.type && type.type === 'numericColumn');
        col.sortable = !type.unsortable;
        col.cellClass = type.cellClass || col.cellClass;
        col.valueFormatter = type.valueFormatter || col.valueFormatter;
        col.width = type.width || col.width;
        col.cellRendererFramework = type.cellRendererFramework || col.cellRendererFramework;
        col.centered = type.centered || col.centered;
        col['custom-sort'] = type.comparaotr || col.comparator;
      }

      // global
      if (this.suppressSorting) {
        col.sortable = false;
      }

    });
    // Vue.$log.debug('bulma columns', JSON.stringify(columns, null, 2));
    return columns;
  }

  get bulmaTable() {
    return !this.agGrid;
  }

  created() {
    this.isCreated = true;
    this.isDeactivated = false;
    if (!this.agGrid) {
      this.loadData({});
    }
  }

  activated() {
    this.isDeactivated = false;
    if (this.isDirty) {
      this.loadData({});
      this.isDirty = false;
    }
  }

  deactivated() {
    this.isDeactivated = true;
    this.isDirty = false;
  }

  // onGridReady(params: any) {
  //   if (this.agGrid) {
  //     this.gridReady = true;
  //     this.loadData(params);
  //   }
  // }

  loadData(gridParams: any) {
    if (this.isDeactivated) {
      return;
    }
    this.rowData = [];
    const params: any = this.params;
    // const paramsKey = Object.keys(params).map(p => params[p]).join('-');
    // if (this.rowDataCache[paramsKey]) {
    //   // using the cache doubles the column-headers :-(
    //   Vue.$log.debug('caching', paramsKey);
    //   this.rowData = this.rowDataCache[paramsKey];
    //   return;
    // }
    this.isLoading = true;
    this.hasError = false;
    STATS_SERVICE.stats(params).then((response: any) => {
      this.rowData = response.data;
      // this.rowDataCache[paramsKey] = this.rowData;
      // if (this.agGrid && this.autoSizeColumns && gridParams && gridParams.api) {
      //   gridParams.api.sizeColumnsToFit();
      // }
    }).catch((error: any) => {
      this.hasError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  @Watch(FOCUS_LEAGUE_ID)
  onFocusLeagueIdChanged() {
    this.onParamChange();
  }

  @Watch(FOCUS_SEASON_ID)
  onFocusSeasonIdChanged() {
    this.onParamChange();
  }

  @Watch(FOCUS_GAME_TYPE_ID)
  onFocusGameTypeIdChanged() {
    this.onParamChange();
  }

  @Watch(FOCUS_TEAM_ID)
  onfocusTeamIdChanged() {
    this.onParamChange();
  }

  @Watch('queryParams.playerId')
  onPlayerIdChanged() {
    this.onParamChange();
  }

  @Watch('params.fieldIds')
  onFieldsChanged() {
    this.onParamChange();
  }

  @Watch('params.query')
  onQueryChanged() {
    this.onParamChange();
  }

  private onParamChange() {
    if (this.isDeactivated) {
      this.isDirty = true;
    } else {
      this.loadData({});
    }
  }

  // onGridSizeChanged(params: any) {
  //   if (!this.gridReady) {
  //     return;
  //   }
  //   if (this.autoSizeColumns) {
  //     params.api.sizeColumnsToFit();
  //   }
  // }

  get params(): StatsParams {
    const providedParams = {
      resource: this.resource,
      query: this.query,
      season: this.queryParams.season,
      league: this.queryParams.league,
      gameType: this.queryParams.gameType,
      team: this.queryParams.team,
      orderBy: this.queryParams.orderBy,
      ha: this.queryParams.ha,
      playerId: this.queryParams.playerId,
      limitRows: this.queryParams.limitRows,
    };
    let params = {
      ...providedParams,
      league: this.focusLeagueId,
      season: this.focusSeasonId,
    };
    if (this.focusTeamId) {
      params = {...params, team: this.focusTeamId};
    }
    return params;
  }

  // get tableHeight(): number {
  //   const avoidScrollOffset = 4;
  //   return this.paginationPageSize * this.ROW_HEIGHT
  //     + this.headerHeight
  //     + this.footerHeight
  //     + avoidScrollOffset;
  // }

  get headerHeight() {
    if (!this.showHeader) {
      return 0;
    }
    return this.HEADER_HEIGHT;
  }

  get footerHeight() {
    if (!this.showFooter || this.suppressPaging) {
      return 0;
    }
    return this.FOOTER_HEIGHT;
  }

  // get pagerHeight() {
  //   if (this.suppressPaging) {
  //     return 0;
  //   }
  //   return this.PAGER_HEIGHT;
  // }

  // get columnTypes() {
  //   return COLUMN_TYPES;
  // }

  // get columnsAgGrid() {
  //   return this.createColumnDefinitions(this.resource, this.fieldIds, true);
  // }

  // localeTextFunc(key: string, defaultValue: string) {
  //   const gridKey = 'grid.' + key;
  //   const value = this.$t(gridKey);
  //   return (value === gridKey) ? defaultValue : value;
  // }

  private createColumnDefinitions(resource: string, fieldIds: string[], sortable: boolean): any[] {
    // this.$log.debug(`fields for '${resource}': sortable = '${sortable}', fieldIds = ${fieldIds}`);
    const definitions = COLUMNS.definitions(resource, fieldIds);
    const fields: any[] = [];
    fieldIds.forEach(fieldId => {
      const definition = definitions[fieldId];
      let field: any = {};
      if (definition) {
        field = definition;
        field.sortable = (sortable && !field.unsortable);
        delete field.unsortable;
      } else {
        field.headerName = `${fieldId.toUpperCase()}!`;
        field.sortable = false;
      }
      field = {...field, field: fieldId};
      fields.push(field);
    });
    // this.$log.debug('mapped fields', fields);
    return fields;
  }

  get pagination(): boolean {
    if (this.suppressPaging) {
      return false;
    }
    return !isNaN(this.paginationPageSize);
  }

  get rowDataLength(): number {
    const data: any[] = this.rowData || [];
    return data.length || [].length;
  }

  get paginationPageSize() {
    let pageSize;
    if (this.suppressPaging) {
      pageSize = this.rowDataLength;
    } else if (this.pageSize) {
      pageSize = this.pageSize;
    } else {
      pageSize = DataTable.DEFAULT_PAGE_SIZE;
      Vue.$log.warn(`No paginationPageSize and not supressing paging. Using default page-size of ${pageSize}`);
    }
    if (pageSize > DataTable.MAX_PAGE_SIZE) {
      Vue.$log.warn(`Page-size too big: propably wont fit into one screen-height. Should not exceed ${DataTable.MAX_PAGE_SIZE}, but it's ${pageSize}`);
    }
    return pageSize;
  }

}

