










import {Component, Prop, Vue} from 'vue-property-decorator';
// TODO [feature] integrate FlagIcons
// import FlagIcon from 'vue-flag-icon';
// Vue.use(FlagIcon);

@Component({
  components: {/*FlagIcon*/},
})
export default class GridCountryFlag extends Vue {

  // TODO [feature] convert countryId to ISO
  // TODO [stats] migrate to bulma-table
  // TODO [feature] country-name / tooltip

  // from bulma-table
  @Prop() props?: any;

  // from ag-grid
  params: any;

  get countryId() {
    if (this.params) {
      return this.params.value.iso;
    }
    if (this.props) {
      return this.props.value;
    }
    Vue.$log.warn('No value for country');
    return '';
  }

}
