







import {Component, Vue, Prop} from 'vue-property-decorator';
import COMMON_UTIL from '@/utils/common.util';

@Component({
    components: {},
})
export default class GridGameLink extends Vue {

  // from bulma-table
  @Prop() props?: any;

  // from ag-grid
  params: any;

  get href() {
    return COMMON_UTIL.links.gameLink(this.gameId);
  }

  get target() {
    return '_parent';
  }

  get gameId() {
    return this.value;
  }

  get value() {
    if (this.params) {
      return this.params.value;
    }
    if (this.props) {
      return this.props.value;
    }
    Vue.$log.warn('No value for GameLink');
    return '';
  }

}
