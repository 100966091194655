







import S from 'string';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ROUTES} from '@/router';
import {PlayerContentType} from '@/model/site/components.model';

@Component({})
export default class GridPlayerLink extends Vue {

  @Prop() linkTarget?: string;
  // from bulma-table
  @Prop() props?: any;

  // from ag-grid
  params: any;

  get dataObj(): any {
    if (this.props) {
      return this.props.data;
    }
    if (this.params) {
      return this.params.data;
    }
    Vue.$log.warn('No data for PlayerLink');
    return {};
  }

  get value(): string {
    if (this.props) {
      return this.props.value;
    }
    if (this.params) {
      return this.params.value;
    }
    Vue.$log.warn('No value for PlayerLink');
    return '';
  }

  get linkTo() {
    return {name: ROUTES.STATS_PLAYER, params: {playerId: this.playerId, contentType: PlayerContentType.STATS}};
  }

  get playerId() {
    return this.value;
  }

  get firstName() {
    if (this.dataObj) {
      return this.dataObj.firstName;
    }
    return '';
  }

  get lastName() {
    if (this.dataObj) {
      return this.dataObj.lastName;
    }
    return '';
  }

  get nameAbb() {
    if (!this.firstName && !this.lastName) {
      Vue.$log.warn(`no first/last-name for ${this.playerId}`);
      return this.playerId;
    }
    let firstAbb = '';
    if (this.firstName) {
      firstAbb = `${S(this.firstName).charAt(0)}. `;
    }
    return `${firstAbb}${this.lastName}`;
  }
}

